import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import Modal from "@material-ui/core/Modal"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import type { MouseEvent } from "react"
import React from "react"
import { getTerminals } from "../../../api"
import { type Order, getComparator, stableSort } from "../../../lib/sort"
import type { TerminalModule } from "../../../types/TerminalModule"
import { EditTerminal } from "../../EditTerminal"
import WaitPopup from "../../WaitPopup"
import { useStyles } from "./styles"
import { type DataTerminalManager, type HeadCell, HeaderDataTerminalManager } from "./types"

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>
	numSelected: number
	onRequestSort: (event: MouseEvent<HTMLElement>, property: keyof DataTerminalManager) => void
	order: Order
	orderBy: string
	rowCount: number
	headCells: HeadCell[]
}

function SortableTableHeaders(props: EnhancedTableProps) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props
	const createSortHandler =
		(property: keyof DataTerminalManager) => (event: MouseEvent<HTMLElement>) => {
			onRequestSort(event, property)
		}
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? "right" : "left"}
							sortDirection={orderBy === headCell.id ? order : false}
							padding={"checkbox"}
							style={{
								backgroundColor: "rgba(4, 101, 170, 1)",
								color: "white",
							}}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								hideSortIcon={true}>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					)
				})}
				<TableCell
					style={{
						width: 5,
						padding: 0,
						margin: 0,
						backgroundColor: "rgba(4, 101, 170, 1)",
						color: "white",
					}}
				/>
			</TableRow>
		</TableHead>
	)
}

export default function () {
	const classes = useStyles()
	const [order, setOrder] = React.useState<Order>("asc")
	const [orderBy, setOrderBy] = React.useState<string>("name")
	const [selectedItem, setSelected] = React.useState<number>()
	const [editID, setEditID] = React.useState<number>()
	const [open, setOpen] = React.useState(false)
	const [terminals, setTerminals] = React.useState<Array<TerminalModule> | undefined>(undefined)
	let openWait = true

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	const handleRequestSort = (
		_event: React.MouseEvent<unknown>,
		property: keyof DataTerminalManager,
	) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
		let newSelected = -1
		newSelected = name
		setSelected(newSelected)
	}

	const loadPageInfo = () => {
		getTerminals(setTerminals)
	}

	const handleOpen = () => {
		setOpen(true)
	}
	const handleClose = () => {
		loadPageInfo()
		setOpen(false)
	}

	const handleUpdateClick = (id: number | null) => {
		setEditID(id ?? -1)
		setOpen(true)
	}

	if (terminals) {
		openWait = false
	}

	return (
		<div style={{ height: "100%", width: "100%" }}>
			<WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={open}
				onClose={handleClose}>
				<div className={classes.modal}>
					<EditTerminal terminalId={editID ?? -1} handleClose={handleClose} />
				</div>
			</Modal>
			<Box style={{ height: "auto", width: "100%", padding: 0 }}>
				<IconButton onClick={(_c) => handleUpdateClick(-1)} style={{ float: "right", padding: 3 }}>
					<AddIcon />
				</IconButton>
			</Box>
			<TableContainer style={{ width: "100%" }}>
				<Table id="terminalManager" stickyHeader={true} size={"small"}>
					<SortableTableHeaders
						classes={classes}
						numSelected={1}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={0}
						headCells={HeaderDataTerminalManager}
					/>
					<TableBody>
						{terminals &&
							stableSort<TerminalModule>(terminals, getComparator(order, orderBy)).map(
								(row, index) => {
									return (
										<TableRow
											key={row.id_}
											hover
											onClick={(event) => handleClick(event, index)}
											tabIndex={-1}
											selected={selectedItem === index}>
											<TableCell>{row.name}</TableCell>
											<TableCell>{row.abbrv}</TableCell>
											<TableCell>{row.contact_email}</TableCell>
											<TableCell style={{ whiteSpace: "pre-line" }}>
												{row.aliases.map((alias) => {
													return `${alias.alias_metadata?.is_recoverable ? "R" : "U"}:${alias.alias}\n`
												})}
											</TableCell>

											<TableCell>
												<IconButton onClick={(_event) => handleUpdateClick(row.id_)}>
													<EditIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									)
								},
							)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
