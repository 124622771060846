import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Modal from "@material-ui/core/Modal"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import TextField from "@material-ui/core/TextField"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"
import { type MouseEvent, useEffect, useState } from "react"
import { updateInvoiceRecManifestRef } from "../../../api"
import { formatCurrency } from "../../../lib/formatters"
import { type Order, getComparator, stableSort } from "../../../lib/sort"
import type { ManifestRef } from "../../../types/RecordSearch"
import TableToExcel from "../../TableToExcel/TableToExcel"
import { useStyles } from "./styles"
import { type HeadCell, HeaderDataInvoiceRecons, type TriniumResultsTableData } from "./types"

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>
	numSelected: number
	onRequestSort: (event: MouseEvent<unknown>, property: keyof TriniumResultsTableData) => void
	order: Order
	orderBy: string
	rowCount: number
	headCells: HeadCell[]
	assignTriniumManifest?: Function
	unassignTriniumManifest?: Function
	disableAssignment?: boolean
}

function SortableTableHeaders(props: EnhancedTableProps) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props
	const createSortHandler =
		(property: keyof TriniumResultsTableData) => (event: MouseEvent<unknown>) => {
			onRequestSort(event, property)
		}
	return (
		<TableHead>
			<TableRow>
				{props.assignTriniumManifest && !props.disableAssignment && (
					<TableCell
						align={"left"}
						padding={"checkbox"}
						style={{
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
						}}>
						{"..."}
					</TableCell>
				)}
				{props.unassignTriniumManifest && !props.disableAssignment && (
					<TableCell
						align={"left"}
						padding={"checkbox"}
						style={{
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
						}}>
						{"..."}
					</TableCell>
				)}
				{headCells.map((headCell) => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? "right" : "left"}
							sortDirection={orderBy === headCell.id ? order : false}
							padding={"checkbox"}
							style={{
								backgroundColor: "rgba(4, 101, 170, 1)",
								color: "white",
							}}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								hideSortIcon={true}>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	)
}

function rand() {
	return Math.round(Math.random() * 15) - 10
}

function getModalStyle() {
	const top = 50 + rand()
	const left = 50 + rand()

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
		maxHeight: "100px",
	}
}

interface Props {
	triniumResults: TriniumResultsTableData[]
	manifestRefData?: ManifestRef[]
	disableControls?: boolean
	maxHeight?: number
	assignTriniumManifest?: Function
	unassignTriniumManifest?: Function
	reloadDataFunction?: Function
	disableAssignment?: boolean
}

export default function (props: Props) {
	const classes = useStyles()
	const [order, setOrder] = useState<Order>("asc")
	const [orderBy, setOrderBy] = useState<string>("manifest")
	const [selectedItem, setSelected] = useState<number>()
	const [daysout, setDaysout] = useState<number>()
	const [openModal, setOpenModal] = useState(false)
	const [modalStyle] = useState(getModalStyle)
	const [assignedManifests, setAssignedItems] = useState<Array<string> | undefined>([])
	const [unassignedManifests, setUnassignedItems] = useState<Array<string> | undefined>([])
	const [manifestRefID, setManifestRefID] = useState<number | undefined>()
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(20)
	let daysOutError = false

	useEffect(() => {
		if (props.triniumResults) {
			for (const result of props.triniumResults) {
				const manifest_rec_ref = props.manifestRefData?.find(
					(manifestRef) =>
						manifestRef.manifest.toLowerCase() === result.full_manifest.toLowerCase(),
				)
				if (manifest_rec_ref) {
					result.id_ = manifest_rec_ref.id_
					result.cost = manifest_rec_ref.cost
					result.days_out = manifest_rec_ref.days_out
				}
			}
		}
	}, [props.triniumResults, props.manifestRefData, assignedManifests])

	const handleRequestSort = (
		_event: MouseEvent<unknown>,
		property: keyof TriniumResultsTableData,
	) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleAssignClick = (manifestID: string) => {
		if (props.assignTriniumManifest) {
			const assignedList = assignedManifests
			const unassignedList = unassignedManifests
			const isAlreadyUnassigned = unassignedList.findIndex((item) => item === manifestID)
			if (isAlreadyUnassigned > 0) {
				unassignedList.splice(isAlreadyUnassigned, 1)
				setUnassignedItems(unassignedList)
			}
			assignedList.push(manifestID)
			setAssignedItems(assignedList)
			props.assignTriniumManifest(manifestID)
		}
	}

	const handleUnassignClick = (manifestID: string) => {
		if (props.unassignTriniumManifest) {
			const assignedList = assignedManifests
			const unassignedList = unassignedManifests
			const isAlreadyAssignedIndex = assignedList.findIndex((item) => item === manifestID)
			if (isAlreadyAssignedIndex > 0) {
				assignedList.splice(isAlreadyAssignedIndex, 1)
				setAssignedItems(assignedList)
			}

			unassignedList.push(manifestID)
			setUnassignedItems(unassignedList)
			props.unassignTriniumManifest(manifestID)
		}
	}

	const handleClick = (event: MouseEvent<unknown>, name: number) => {
		let newSelected = -1
		newSelected = name
		setSelected(newSelected)
	}

	const handlePageChange = (event, newPage) => {
		setPage(newPage)
	}

	const handleRowsPerPageChange = (event) => {
		setRowsPerPage(Number.parseInt(event.target.value, 10))
		setPage(0)
	}

	const handleTextChange = (event: any, setFunction: Function) => {
		setFunction(event.target.value)
	}

	const handleOpen = (_event: MouseEvent<unknown>, manifest: any) => {
		if (props.manifestRefData?.find((ref) => ref.manifest === manifest)) {
			setManifestRefID(props.manifestRefData.find((ref) => ref.manifest === manifest).id_)
			setOpenModal(true)
		}
	}

	function handleSave() {
		if (daysout > 0 && !daysOutError) {
			updateInvoiceRecManifestRef(manifestRefID, daysout, () => {
				if (props.reloadDataFunction) {
					props.reloadDataFunction()
				}
				setOpenModal(false)
			})
		}
	}

	const checkOrEx = (flag: boolean) => {
		if (flag) {
			return <CheckIcon className={classes.check} />
		}
		return <ClearIcon className={classes.clear} />
	}

	if (daysout && daysout > 0) {
		daysOutError = false
	} else {
		daysOutError = true
	}

	const maxHeight = props.maxHeight ? `${props.maxHeight.toString()}px` : "450px"
	if (props.triniumResults && props.triniumResults.length > 0) {
		return (
			<div style={{ maxHeight: "30%", width: "100%" }}>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={openModal}
					onClose={() => setOpenModal(false)}>
					<div style={modalStyle} className={classes.paper}>
						<div>
							<Grid container>
								<Grid item sm={12} style={{ margin: "auto" }}>
									<Box>CUSTOM DAYS OUT </Box>
								</Grid>
								<Grid item sm={12} style={{ margin: "auto" }}>
									<TextField
										error={daysOutError}
										id="outlined-basic"
										variant="outlined"
										type="number"
										value={daysout}
										onChange={(event: any) => {
											handleTextChange(event, setDaysout)
										}}
									/>
									<Grid item sm={12} style={{ margin: "auto" }}>
										<Button
											style={{ margin: 5 }}
											onClick={handleSave}
											variant="outlined"
											color="primary">
											Save
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</div>
					</div>
				</Modal>
				<Box style={{ height: "auto", width: "100%", padding: 0 }}>
					<TableToExcel
						id="tte_trinium"
						sheet="Worksheet"
						table="trinium_search_results"
						fileName={`trinium_search_results_${Date.now()}`}
						buttonText=""
						className=""
					/>
				</Box>
				<TableContainer style={{ maxHeight: maxHeight }}>
					<Table id="trinium_search_results" stickyHeader={true} size={"small"}>
						<SortableTableHeaders
							classes={classes}
							numSelected={1}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={props.triniumResults.length}
							headCells={HeaderDataInvoiceRecons}
							assignTriniumManifest={props.assignTriniumManifest}
							unassignTriniumManifest={props.unassignTriniumManifest}
							disableAssignment={props.disableAssignment}
						/>
						<TableBody>
							{stableSort(props.triniumResults, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row: TriniumResultsTableData, index) => {
									return (
										<TableRow
											key={row.full_manifest}
											hover
											onClick={(event) => handleClick(event, index)}
											onDoubleClick={(event) => handleOpen(event, row.full_manifest)}
											tabIndex={-1}
											selected={selectedItem === index}>
											{props.assignTriniumManifest && !props.disableAssignment && (
												<TableCell>
													<Button
														color="primary"
														onClick={() => handleAssignClick(row.full_manifest)}>
														{assignedManifests.find((item) => item === row.full_manifest)
															? checkOrEx(true)
															: "Assign"}
													</Button>
												</TableCell>
											)}
											{props.unassignTriniumManifest && !props.disableAssignment && (
												<TableCell>
													<Button
														color="primary"
														onClick={() => {
															handleUnassignClick(row.full_manifest)
														}}>
														{unassignedManifests.find((item) => item === row.full_manifest)
															? checkOrEx(true)
															: "Unassign"}
													</Button>
												</TableCell>
											)}
											<TableCell>
												{row.full_manifest ? row.full_manifest.toUpperCase() : ""}
											</TableCell>

											<TableCell>{row.chassis ? row.chassis.toUpperCase() : ""}</TableCell>
											<TableCell>
												{row.container_with_check ? row.container_with_check.toUpperCase() : ""}
											</TableCell>
											<TableCell>
												{row.customer_name ? row.customer_name.toUpperCase() : ""}
											</TableCell>
											<TableCell>{row.ssl ? row.ssl.toUpperCase() : ""}</TableCell>
											<TableCell>{row.site ? row.site.toUpperCase() : ""}</TableCell>
											<TableCell>{row.booking_bol ? row.booking_bol.toUpperCase() : ""}</TableCell>
											<TableCell>{row.pickup ? row.pickup.toUpperCase() : ""}</TableCell>
											<TableCell>{row.pickup_date ? row.pickup_date.format("L") : ""}</TableCell>
											<TableCell>{row.delivery ? row.delivery.toUpperCase() : ""}</TableCell>
											<TableCell>
												{row.delivery_date ? row.delivery_date.format("L") : ""}
											</TableCell>
											<TableCell>
												{row.ar_chuse
													? `$${formatCurrency(row.ar_chuse)}`
													: row.ar_trx
														? `$${formatCurrency(row.ar_trx)}`
														: ""}
											</TableCell>
											<TableCell>{row.cost ? `$${formatCurrency(row.cost)}` : ""}</TableCell>
											<TableCell>{row.days_out ? row.days_out : ""}</TableCell>
										</TableRow>
									)
								})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10]}
					component="div"
					count={props.triniumResults.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handlePageChange}
					onRowsPerPageChange={handleRowsPerPageChange}
				/>
			</div>
		)
	}
	return (
		<div>
			<h3>No Records</h3>
		</div>
	)
}
