import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import React from "react"
import {
	addCommentsToInvoiceRecord,
	getDisputeReasons,
	getInvoice,
	getInvoiceRecordWithDisputes,
	getLatestDisputes,
	updateOrCreateDispute,
	updatedTerminalStatus,
	uploadTermChassisFiles,
} from "../api"
import { formatCurrency, newFormatDateMMDDYYYY } from "../lib/formatters"
import { newDateFromStr } from "../lib/util"
import { authStore } from "../stores/authStore"
import type { InvoiceRecordComment } from "../types/InvoiceRecordComment"
import InvRecSupportFiles from "./InvRecSupportFiles"
import WaitPopup from "./WaitPopup"
import UploadComp from "./upload/UploadComponent"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: "absolute",
			width: 900,
			maxHeight: 700,
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		check: {
			color: "rgba(65, 150, 68, 1)",
		},
		clear: {
			color: "rgba(245, 84, 44, 1)",
		},
		panel: {
			backgroundColor: "rgba(235, 235, 235, 1)",
			width: "auto",
		},
	}),
)

export default function InvoiceRecordDisputeDetails(props: any) {
	const classes = useStyles()
	const [state, setState] = React.useState(false)
	const [amount, setAmount] = React.useState(0)
	const [credit, setCredit] = React.useState(0)
	const [file, setFile] = React.useState<any>()
	const [invoiceWithDisputes, setInvoiceWithDisputes] = React.useState<any | undefined>()
	const [comments, setComments] = React.useState<InvoiceRecordComment[] | undefined>()
	const [internalComments, setInternalComments] = React.useState<string>("")
	const [addInternalComments, setAddInternalComments] = React.useState<string>("")
	const [externalComments, setExternalComments] = React.useState<string>("")
	const [addExternalComments, setAddExternalComments] = React.useState<string>("")
	const [addedComment, setAddedComment] = React.useState<any | undefined>()
	const [waitMsg, setWaitMsg] = React.useState<string | undefined>("...Loading Info")
	const [openWait, setOpenWait] = React.useState<boolean>(true)
	const [latestDispute, setLatestDispute] = React.useState<any | undefined>()
	const [createdDispute, setCreatedDispute] = React.useState<any | undefined>()
	const [terminalStatus, setTerminalStatus] = React.useState<any | undefined>()
	const [refreshSupportFiles, setRefreshSupportFiles] = React.useState<any | undefined>()
	const [disputeReasons, setDisputeReasons] = React.useState<any | undefined>()
	const [invoice, setInvoice] = React.useState<any | undefined>()
	const invoiceRecID = props.invoiceRecordID
	const disableControls = props.disableControls
	const auth = authStore.use()
	const me = auth.me
	const afterFirstRenderRef = React.useRef(false)

	React.useEffect(() => {
		if (invoiceRecID) {
			loadPageInfo()
		}
	}, [])

	// to control the loading
	React.useEffect(() => {
		if (!afterFirstRenderRef.current) {
			afterFirstRenderRef.current = true
			return
		}
		setOpenWait(false)
	}, [invoiceWithDisputes])
	React.useEffect(() => {
		if (invoice) {
			setOpenWait(false)
		}
	}, [invoice])

	React.useEffect(() => {
		if (comments && comments.length > 0) {
			const internal = []
			const external = []

			comments.sort((a: any, b: any) => {
				return b.created_dt - a.created_dt
			})
			comments.forEach((element) => {
				if (element.is_internal_comment) {
					internal.push(element)
				} else {
					external.push(element)
				}
			})
			formatComments(internal, setInternalComments)
			formatComments(external, setExternalComments)
		}
	}, [comments])

	React.useEffect(() => {
		if (createdDispute) {
			setOpenWait(false)
			loadPageInfo()
		}
	}, [createdDispute])

	React.useEffect(() => {
		if (addedComment) {
			if (amount > 0) {
				const disputeObj: any = {}
				disputeObj.username = me.username
				disputeObj.invoiceRecordID = invoiceRecID
				disputeObj.disputedAmt = amount
				disputeObj.paidResolved = state
				setWaitMsg("...Updating Dispute")
				setOpenWait(true)
				updateOrCreateDispute(disputeObj, setCreatedDispute)
			}

			if (file) {
				const formData = new FormData()
				const additdata = '{"username":"' + me.username + '"}'
				formData.append("data", additdata)
				formData.append("file", file)
				setOpenWait(true)
				setWaitMsg("...Uploading File")
				uploadTermChassisFiles(formData, invoiceRecID, true).then(() => {
					setOpenWait(false)
				})
			}
			loadPageInfo()
			setRefreshSupportFiles(true)
			setAddExternalComments("")
			setAddInternalComments("")
		}
	}, [addedComment])

	React.useEffect(() => {
		if (invoiceWithDisputes && invoiceWithDisputes.comments) {
			for (const comment of invoiceWithDisputes.comments) {
				comment.created_dt = newDateFromStr(comment.created_dt)
			}
			setComments(invoiceWithDisputes.comments)
		}
		if (
			latestDispute &&
			latestDispute.disp_amt &&
			invoiceWithDisputes &&
			invoiceWithDisputes.total_billed
		) {
			setAmount(latestDispute.disp_amt)
		}
		if (latestDispute && latestDispute.credit_amt) {
			setCredit(latestDispute.credit_amt)
		}

		if (latestDispute && latestDispute.paid_resolved) {
			setState(latestDispute.paid_resolved)
		} else {
			setState(false)
		}

		if (invoiceWithDisputes) {
			setOpenWait(true)
			afterFirstRenderRef.current = false
			getInvoice(invoiceWithDisputes.iep_invoice_id, setInvoice)
		}
	}, [latestDispute, invoiceWithDisputes])

	React.useEffect(() => {
		if (terminalStatus) {
			handleSaveClick()
		}
	}, [terminalStatus])

	const formatComments = (listOfComments: any[], callback: Function) => {
		let commentsStr = ""
		listOfComments.forEach((comm) => {
			commentsStr += `[${newFormatDateMMDDYYYY(comm.created_dt)}](${comm.user_name}):\n`
			commentsStr += `${comm.comment}\n`
		})
		callback(commentsStr)
	}

	const loadPageInfo = () => {
		setOpenWait(true)
		getInvoiceRecordWithDisputes(invoiceRecID, setInvoiceWithDisputes)
		getLatestDisputes(invoiceRecID).then((data) => setLatestDispute(data))
		getDisputeReasons(setDisputeReasons)
	}

	const handleTextChange = (event: any) => {
		event.target.value = event.target.value.match(/[0-9]+\.?[0-9]?[0-9]?/)
		setAmount(event.target.value)
	}

	const handleChange = (event: any) => {
		if (
			(invoice &&
				invoice.ignore_update_dt === true &&
				state === true &&
				invoiceWithDisputes &&
				!invoiceWithDisputes.ap_summary_id) ||
			(invoice && invoice.is_completed === false ? false : true)
		) {
			setState(event.target.checked)
		}
	}

	const handleInternalTextChange = (event: any) => {
		setAddInternalComments(event.target.value)
	}

	const handleExternalTextChange = (event: any) => {
		setAddExternalComments(event.target.value)
	}

	const handleSaveClick = () => {
		if (invoiceRecID) {
			const invoiceRecObj: any = {}

			invoiceRecObj.invoiceRecordID = invoiceRecID
			invoiceRecObj.username = me.username
			invoiceRecObj.comments = []

			if (addInternalComments) {
				invoiceRecObj.comments.push({ comment: addInternalComments, isInternalComment: true })
			}
			if (addExternalComments) {
				invoiceRecObj.comments.push({ comment: addExternalComments, isInternalComment: false })
			}
			setWaitMsg("...Saving Comments")
			setOpenWait(true)
			addCommentsToInvoiceRecord(invoiceRecObj, (data) => {
				setOpenWait(false)
				setAddedComment(data)
			})
		}
	}

	const handleReplyClick = () => {
		if (invoiceWithDisputes.matches) {
			const sendToTerminalObj: any = {}
			sendToTerminalObj.invoice_record_id = invoiceRecID
			sendToTerminalObj.username = me.username
			updatedTerminalStatus(sendToTerminalObj, setTerminalStatus)
		}
	}

	const handleDDSwitchChange = (event, isExternal) => {
		let pretext = ""
		if (isExternal) {
			if (addExternalComments) {
				pretext = addExternalComments + "\n"
			}
			pretext = pretext + disputeReasons.find((ele) => ele.id_ == event.target.value).reason
			setAddExternalComments(pretext)
		} else {
			if (addInternalComments) {
				pretext = addInternalComments + "\n"
			}
			pretext = pretext + disputeReasons.find((ele) => ele.id_ == event.target.value).reason
			setAddInternalComments(pretext)
		}
	}

	return (
		<Box className={classes.panel}>
			<WaitPopup open={openWait}>{waitMsg}</WaitPopup>
			<Grid
				container
				style={{
					overflow: "hidden",
					padding: 10,
				}}>
				<Grid item sm={3}>
					<Grid container>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<Box style={{ minHeight: 20 }}>Internal Comments</Box>
						</Grid>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<TextField
								id="outlined-multiline-static"
								disabled={true}
								multiline
								rows="4"
								defaultValue="Default Value"
								variant="outlined"
								value={internalComments ? internalComments.toUpperCase() : ""}
								style={{ backgroundColor: "white", width: "90%" }}
							/>
						</Grid>
						<Grid item sm={12}>
							<TextField
								size="small"
								select
								disabled={props.disableControls ? props.disableControls : false}
								variant="outlined"
								value={-1}
								onChange={(event) => {
									handleDDSwitchChange(event, false)
								}}>
								{disputeReasons ? (
									disputeReasons.map((item) => {
										return (
											<MenuItem key={item.id_} value={item.id_}>
												{item.reason}
											</MenuItem>
										)
									})
								) : (
									<MenuItem></MenuItem>
								)}
							</TextField>
						</Grid>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<TextField
								id="outlined-multiline-static"
								multiline
								disabled={props.disableControls ? props.disableControls : false}
								rows="2"
								variant="outlined"
								value={addInternalComments}
								onChange={handleInternalTextChange}
								style={{ backgroundColor: "white", width: "90%" }}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={3}>
					<Grid container>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<Box style={{ minHeight: 20 }}>External Comments</Box>
						</Grid>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<TextField
								id="outlined-multiline-static"
								disabled={true}
								multiline
								rows="4"
								defaultValue="Default Value"
								variant="outlined"
								value={externalComments ? externalComments.toUpperCase() : ""}
								style={{ backgroundColor: "white", width: "90%" }}
							/>
						</Grid>
						<Grid item sm={12}>
							<TextField
								size="small"
								select
								disabled={props.disableControls ? props.disableControls : false}
								variant="outlined"
								value={-1}
								onChange={(event) => {
									handleDDSwitchChange(event, true)
								}}>
								{disputeReasons ? (
									disputeReasons.map((item) => {
										return (
											<MenuItem key={item.id_} value={item.id_}>
												{item.reason}
											</MenuItem>
										)
									})
								) : (
									<MenuItem></MenuItem>
								)}
							</TextField>
						</Grid>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<TextField
								id="outlined-multiline-static"
								multiline
								rows="2"
								variant="outlined"
								disabled={props.disableControls ? props.disableControls : false}
								value={addExternalComments}
								onChange={handleExternalTextChange}
								style={{ backgroundColor: "white", width: "90%" }}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={3}>
					<Grid container>
						<Grid item sm={12} id="grid-spacer" style={{ paddingBottom: 10 }}>
							<Box style={{ minHeight: 20 }} />
						</Grid>
						<Grid item sm={12} style={{ paddingBottom: 0 }}>
							<TextField
								id="outlined-multiline-static"
								label="Enter disputed amount ($)"
								variant="outlined"
								onChange={handleTextChange}
								disabled={props.disableControls ? props.disableControls : false}
								value={amount}
								style={{
									backgroundColor: "white",
									width: "90%",
								}}
							/>
						</Grid>
						<Grid item sm={12}>
							<Box>
								Initial amount: $
								{invoiceWithDisputes ? formatCurrency(invoiceWithDisputes.total_billed) : null}
							</Box>
						</Grid>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<Box>Credited: ${credit ? formatCurrency(credit) : null}</Box>
						</Grid>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<UploadComp setFile={setFile} style={{ width: "90%" }} />
						</Grid>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<InvRecSupportFiles refresh={refreshSupportFiles} invoiceRecID={invoiceRecID} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={3}>
					<Grid container>
						<Grid item sm={12} id="grid-spacer" style={{ paddingBottom: 10 }}>
							<Box style={{ minHeight: 20 }} />
						</Grid>
						<Grid item sm={12}>
							<FormGroup row>
								<FormControlLabel
									disabled={
										(invoice &&
											invoice.ignore_update_dt === true &&
											state === true &&
											invoiceWithDisputes &&
											!invoiceWithDisputes.ap_summary_id) ||
										(invoice && invoice.is_completed === false)
											? false
											: true
									}
									control={
										<Checkbox
											checked={state}
											disabled={!invoice}
											onChange={handleChange}
											name="finalize"
											value="checked"
											color="primary"
										/>
									}
									label="Finalized & Closed"
								/>
							</FormGroup>
						</Grid>
						<Grid item sm={12} id="grid-spacer" style={{ paddingBottom: 10 }}>
							<Box style={{ minHeight: 20 }} />
						</Grid>
						<Grid item sm={12} id="grid-spacer" style={{ paddingBottom: 10 }}>
							<Box style={{ minHeight: 20 }} />
						</Grid>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<Button
								style={{ margin: 5 }}
								size="small"
								variant="contained"
								color="primary"
								disabled={props.disableControls ? props.disableControls : false}
								onClick={handleSaveClick}>
								SAVE
							</Button>
							<Button
								style={{ margin: 5 }}
								size="small"
								variant="contained"
								color="primary"
								disabled={props.disableControls ? props.disableControls : false}
								onClick={handleReplyClick}>
								REPLY TO TERMINAL
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}
