import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import React from "react"
import { deleteAPSummary, getAPSummaryByInvoiceID, getFullInvoiceAP } from "../api"
import APHistoryTable from "../components/CustomTable/apHistoryTable/APHistoryTable"
import APSummaryTable from "../components/CustomTable/apSummaryTable/APSummaryTable"
import WaitPopup from "../components/WaitPopup"
import Layout from "../components/layout/Layout"
import { formatDateMMDDYYYY } from "../lib/formatters"

interface APsummaryProps {
	match: {
		params: {
			id: number
		}
	}
}
export default function (props: APsummaryProps) {
	const [waitMsg, setWaitMsg] = React.useState<string | undefined>("...Loading Info")
	const [openWait, setOpenWait] = React.useState<boolean>(false)
	const [invoice, setInvoice] = React.useState<any | undefined>()
	const [apHistory, setAPHistory] = React.useState<any | undefined>()
	const [apSummary, setAPSummary] = React.useState<any | undefined>()

	const invoiceID = props.match.params.id
	React.useEffect(() => {
		setOpenWait(true)
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (invoice) {
			setOpenWait(false)
		}
	}, [invoice])

	React.useEffect(() => {
		if (apSummary) {
			const apHistSums = apSummary
			apHistSums.forEach((item) => {
				// todo: this date converts to local always and could be wrong
				if (item.documented_dt) {
					item.documented_dt = new Date(item.documented_dt.replace(" GMT", ""))
				}
				if (item.pay_to_month) {
					item.pay_to_month = new Date(item.pay_to_month.replace(" GMT", ""))
				}
			})

			setAPHistory(apHistSums)
		}
	}, [apSummary])

	const handleDeleteClick = (id) => {
		setOpenWait(true)
		deleteAPSummary(id, (_data) => {
			reloadPageInfo()
		})
	}

	const loadPageInfo = () => {
		if (!invoice) {
			getFullInvoiceAP(invoiceID).then((data) => {
				setInvoice(data)
			})
			getAPSummaryByInvoiceID(invoiceID, setAPSummary)
		}
	}

	const reloadPageInfo = () => {
		getFullInvoiceAP(invoiceID).then((data) => {
			setInvoice(data)
		})
		getAPSummaryByInvoiceID(invoiceID, setAPSummary)
	}

	return (
		<Layout>
			<Paper>
				<WaitPopup open={openWait}>{waitMsg}</WaitPopup>
				<Box style={{ margin: 10, height: "100%" }}>
					<Grid container style={{ padding: 15 }}>
						<Grid item sm={12} style={{ height: 50 }}>
							<Grid container>
								<Grid item sm={10}>
									<Box>
										<h4>
											INVOICE AP SUMMARY: Invoice # {invoice?.invoice_num} -{" "}
											{invoice &&
												formatDateMMDDYYYY(new Date(invoice.documented_dt.replace(" GMT", "")))}
										</h4>
									</Box>
								</Grid>
							</Grid>
						</Grid>
						<Grid item sm={12}>
							<APSummaryTable
								invoiceData={invoice ? invoice.InvoiceRecords : []}
								invoiceID={Number(invoiceID)}
								invoice_data_changed={reloadPageInfo}
							/>
						</Grid>
						<Grid item sm={12} style={{ marginTop: 50 }}>
							<Box>
								<h4>AP SUMMARY HISTORY</h4>
							</Box>
						</Grid>
						<Grid item sm={12}>
							<APHistoryTable apSummaries={apHistory} handleDeleteClick={handleDeleteClick} />
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Layout>
	)
}
