import { format as formatDate } from "date-fns"

export function formatNumber(num: number): string {
	if (typeof num === "number" && num === num) {
		return num
			.toFixed(2)
			.replace(/\.00$/, "")
			.replace(/\.(\d)0$/, ".$1")
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
	}
	return num + ""
}

export function formatCurrency(num: number): string {
	if (typeof num === "number" && num === num) {
		return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
	}
	return num + ""
}

/**
 * @deprecated Properly convert the date from the back end, then use `newFormatDateMMDDYYYY()` instead.
 */
export function formatDateMMDDYYYY(date: Date, withDashes?: boolean) {
	if (date && typeof date === "string") {
		date = new Date(date)
	}

	const withoutTimezone = new Date(date.valueOf()).toISOString().slice(0, -1)

	date = new Date(withoutTimezone)

	if (withDashes) {
		return date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear()
	}

	return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
}


export function newFormatDateMMDDYYYY(date: Date, withDashes?: boolean) {
	if (date && typeof date === "string") {
		date = new Date(date)
	}
	if (withDashes) {
		return formatDate(date, "MM-DD-yyyy")
	}
	return formatDate(date, "MM/dd/yyyy")
}

export function formatDateMMDDYYYYAndTime(date: Date) {
	return formatDate(date, "MM/dd/yyyy hh:mm a")
}

export function formatPascalToString(text: string) {
	var wordList = []
	var phrase = ""
	if (text) {
		wordList = text.match(/[A-Z][a-z]+/g)
		wordList.forEach((word) => {
			phrase += " " + word
		})
	}
	return phrase
}


